import React from 'react';

const NotFoundPage = () => {

    return (
        <div>
            NotFoundPage
        </div>
    );
}
export default NotFoundPage;