import React from "react";
import Home from "../functions/Home/Home"

const routes = {
    '/': () => <table className="top-table _home">
    <tbody>
        <tr>
            <td valign="top" height="100%"><Home /></td>
        </tr>
    </tbody>
</table>,
    '/home': () => <table className="top-table _home">
        <tbody>
            <tr>
                <td valign="top" height="100%"><Home /></td>
            </tr>
        </tbody>
    </table>,
    '/news': () => <table className="top-table _news">
        <tbody>
            <tr>
                <td valign="top" height="100%"><Home /></td>
            </tr>
        </tbody>
    </table>,
};
export default routes;


