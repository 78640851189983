const initialState = {
    //   techList: ["TypeScript", "React Hooks"],
    user: {},
    mouseClick: [],
    returnKey: [],
    windowWidthHeight: { width: 0, height: 0 },
    errorMessages: [],
    createPostIdOpened: 0,
    forumThreads: [],
    newPost: false,
    userIsLoggedIn: false,
    waiting: true,
    forumPostsDeleted: [],
    forumPostAdded: {},
    avatarRefreshString: '',
    os: ''
};
const types = {
    //   SET_TECH_LIST: "SET_TECH_LIST",
    //   ADD_TO_TECH_LIST: "ADD_TO_TECH_LIST",
    //    REMOVE_FROM_TECH_LIST: "REMOVE_FROM_TECH_LIST",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    MOUSE_DOWN: "MOUSE_DOWN",
    MOUSE_UP: "MOUSE_UP",
    RETURN_KEY: "RETURN_KEY",
    WINDOW_WIDTH_HEIGHT: "WINDOW_WIDTH_HEIGHT",
    ADD_ERROR_MESSAGES: "ADD_ERROR_MESSAGES",
    CLEAR_ERROR_MESSAGES: "CLEAR_ERROR_MESSAGES",
    CREATE_POST_ID_OPEN: "CREATE_POST_ID_OPEN",
    RELOAD_POSTS: "RELOAD_POSTS",
    FORUM_THREADS: "FORUM_THREADS",
    NEW_POST: "NEW_POST",
    WAITING: "WAITING",
    UPDATE_USER_ACTION: "UPDATE_USER_ACTION",
    FORUM_POSTS_DELETED: "FORUM_POSTS_DELETED",
    FORUM_POST_ADDED: "FORUM_POST_ADDED",
    AVATAR_REFRESH_STRING: "AVATAR_REFRESH_STRING",
    OS: "OS"
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case types.SET_TECH_LIST:
        //     return {
        //         ...state,
        //         techList: action.payload
        //     };
        // case types.ADD_TO_TECH_LIST:
        //     return {
        //         ...state,
        //         techList: [...state.techList, action.payload]
        //     };
        // case types.REMOVE_FROM_TECH_LIST:
        //     return {
        //         ...state,
        //         techList: state.techList.filter(
        //             tech => tech !== action.payload)
        //     };

        case types.LOGIN_USER:
            return {
                ...state,
                user: action.payload,
                userIsLoggedIn: true
            };
        case types.LOGOUT_USER:
            return {
                ...state,
                user: [],
                userIsLoggedIn: false
            };
        case types.MOUSE_DOWN:
            return {
                ...state,
                mouseDown: [action.payload]
            };
        case types.MOUSE_UP:
            return {
                ...state,
                mouseClick: [action.payload]
            };
        case types.RETURN_KEY:
            return {
                ...state,
                returnKey: [...state.returnKey, action.payload]
            };
        case types.WINDOW_WIDTH_HEIGHT:
            return {
                ...state,
                windowWidthHeight: action.payload
            };
        case types.ERROR_MESSAGES:
            if (typeof action.payload === 'string') {
                action.payload = [action.payload]
            }
            if (state.errorMessages.indexOf(action.payload[0]) > -1) {
                return state
            }
            return {
                ...state,
                errorMessages: [...state.errorMessages, ...action.payload]
            };
        case types.CLEAR_ERROR_MESSAGES:
            return {
                ...state,
                errorMessages: []
            };
        case types.CREATE_POST_ID_OPEN:
            return {
                ...state,
                createPostIdOpened: action.payload
            };
        case types.RELOAD_POSTS:
            return {
                ...state,
                reloadPosts: action.payload
            };
        case types.FORUM_THREADS:
            let payload = action.payload
            if (Array.isArray(payload) === false) {
                payload = [payload]
                return {
                    ...state,
                    forumThreads: [...payload, ...state.forumThreads] // put the payload first cause it'll be the top thread.
                }
            }
            return {
                ...state,
                forumThreads: [...state.forumThreads, ...payload]
            };
        case types.NEW_POST:
            return {
                ...state,
                newPost: action.payload
            }
        case types.WAITING:
            return {
                ...state,
                waiting: action.payload
            }
        case types.UPDATE_USER_ACTION:
            return {
                ...state,
                user: action.payload,
            }
        case types.FORUM_POSTS_DELETED:
            return {
                ...state,
                forumPostsDeleted: action.payload
            }
        case types.FORUM_POST_ADDED:
            return {
                ...state,
                forumPostAdded: action.payload
            }
        case types.AVATAR_REFRESH_STRING:
            return {
                ...state,
                avatarRefreshString: action.payload
            }
        case types.OS:
            return {
                ...state,
                os: action.payload
            }
        default:
            throw new Error("Unexpected action");
    }
};
export { initialState, types, reducer };