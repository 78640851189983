import { config } from '../consts/config';

let Utility = {

    isJsonParsable: (str) => {
        if (!str) {
            return false
        }
        if (typeof str !== 'string') {
            return false
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false
        }
        return true
    },

    parseJson: (str) => {
        if (!str) {
            return false
        }
        if (typeof str !== 'string') {
            return false
        }
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    },

    // the json.dumps method on the server does something to the date string that changes it to a flat string. So I had to do a special conversion fn for it.
    formatDate: (dateString) => {
        if (dateString) {
            if (dateString[3] === ',') { // if its of format Sat, 26 Jam 2019 00:00:00 GMT
                let date_arr = dateString.split(' ')
                let formattedDate = date_arr[2] + ' ' + date_arr[1] + ', ' + date_arr[3]
                return formattedDate
            }
            else {
                let date_arr1 = dateString.split(' ')[0]
                let date_arr2 = date_arr1.split('-')
                let mth = ''
                switch (parseInt(date_arr2[1])) {
                    case 1: mth = 'Jan'
                        break
                    case 2: mth = 'Feb'
                        break
                    case 3: mth = 'Mar'
                        break
                    case 4: mth = 'Apr'
                        break
                    case 5: mth = 'May'
                        break
                    case 6: mth = 'Jun'
                        break
                    case 7: mth = 'Jul'
                        break
                    case 8: mth = 'Aug'
                        break
                    case 9: mth = 'Sep'
                        break
                    case 10: mth = 'Oct'
                        break
                    case 11: mth = 'Nov'
                        break
                    case 12: mth = 'Dec'
                        break
                    default: mth = '0'
                }

                let formattedDate = mth + ' ' + date_arr2[2] + ', ' + date_arr2[0]
                return formattedDate
            }
        }
    },

    clone: (items) => items.map(item => (Array.isArray(item) ? Utility.clone(item) : item)),

    getClosestElementMatchingName: (elem, name) => {
        if (!elem || !elem.parentNode) {
            return null
        }
        if (!name || typeof name !== 'string') {
            return null
        }
        for (; elem && elem !== document; elem = elem.parentNode) {
            let testname = elem.getAttribute('name')
            if (testname === name) {
                return name
            }
        }

        return null
    },

    rgbToHex: (r, g, b) => {
        if (typeof r === 'string') {
            r = parseInt(r)
        }
        if (typeof g === 'string') {
            g = parseInt(g)
        }
        if (typeof b === 'string') {
            b = parseInt(b)
        }
        if (Utility.isInt(r) && r >= 0 && r <= 255 &&
            Utility.isInt(g) && g >= 0 && g <= 255 &&
            Utility.isInt(b) && b >= 0 && b <= 255) {
            return '#' + [r, g, b]
                .map(x => x.toString(16).padStart(2, '0')).join('').toUpperCase()
        }
        return '#000000'
    },

    isInt: (n) => !isNaN(parseInt(n, 10)) && isFinite(n),

    isNumeric: (n) => !isNaN(parseFloat(n)) && isFinite(n),

    requestId: () => Math.floor(Math.random() * ((99999 - 11111) + 1) + 11111),

    randomString: (len) => [...Array(len)].map(() => Math.random().toString(36)[2]).join(''),

    getConfig: () => config,

    lowerCase: str => {
        if (str) {
            if (typeof str === 'string') {
                return str.toLowerCase()
            }
        }
        return str
    },

    intVal: (n) => {
        if (!n) {
            return 0
        }
        if (Utility.isNumeric(n) === false) {
            return parseInt(n)
        }
        return n
    },

    roundFloat: (nbr, dec_places = 0) => {
        var mult = Math.pow(10, dec_places);
        return Math.round(nbr * mult) / mult;
    },

    sluggify: (txt) => {
        return Utility.replaceAll(txt, ' ', + '_')
    },

    deSluggify: (txt) => {
        return Utility.replaceAll(txt, '_', + ' ')
    },

    getIpAddress: () => {
        let existingIpAddress = window.localStorage.getItem('ip')
        if (existingIpAddress) {
            return existingIpAddress
        }
        return new Promise((resolve, reject) => {
            fetch("https://extreme-ip-lookup.com/json").then((response) => {
                response.json().then(obj => {
                    console.log('obj: ', obj)
                })
            },
                (error) => {
                    resolve()
                })
        }).catch(error => { })
    },

    emptyCheck: (data) => {
        if (typeof (data) == 'number' || typeof (data) == 'boolean') {
            return false;
        }
        if (typeof (data) == 'undefined' || data === null) {
            return true;
        }
        if (typeof (data.length) != 'undefined') {
            return data.length === 0;
        }
        var count = 0;
        for (var i in data) {
            if (data.hasOwnProperty(i)) {
                count++;
            }
        }
        return count === 0;
    },

    isObjectAndNotEmpty: (obj) => Object.entries(obj).length > 0 && obj.constructor === Object,

    escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|[]\/\\])/g, "\\$1");
    },

    replaceAll(str, find, replace) {
        if (!str || !find || !replace) {
            return str;
        }
        return str.replace(new RegExp(Utility.escapeRegExp(find), 'g'), replace);
    },

    detectOS: () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'mac';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'windows';
        } else if (/Android/.test(userAgent)) {
            os = 'android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'linux';
        }

        return os;
    },

    detectBrowser: () => {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
            // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
            //"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
            // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            sBrowser = "unknown";
        }
        return sBrowser;
    },

    getElementBBox: (ident) => {
        let element = document.getElementById(ident)
        if (!element) {
            element = document.getElementsByClassName(ident)[0];
        }
        if (!element) {
            return 0
        }
        var positionInfo = element.getBoundingClientRect();
        if (!positionInfo) {
            return 0
        }
        return positionInfo
    },

    getElementWidth: (ident) => {
        let bbox = Utility.getElementBBox(ident)
        if (!bbox) {
            return 0
        }

        return bbox.width;
    },

    getElementHeight: (ident) => {
        let bbox = Utility.getElementBBox(ident)
        if (!bbox) {
            return 0
        }

        return bbox.height;
    },

    getCombinedElementsWidth: (className) => {
        let elements = null
        if (className) {
            elements = document.getElementsByClassName(className)
        }
        if (!elements) {
            return 0
        }
        let elementsWidth = 0;
        Array.from(elements).forEach((element) => {
            let elementRect = element.getBoundingClientRect()
            if (elementRect) {
                elementsWidth += elementRect.width
            }
        })

        return elementsWidth
    },

    cleanUrlText: (text) => {
        //text = text.replace(/\&/g, ' ')
        //text = text.replace(/\%/g, ' ')
        text = text.replace(/&/g, ' ')
        text = text.replace(/%/g, ' ')
        text = text.replace(/[0-9]/g, ' ');
        text = text.replace(/\s\s+/g, ' ');
        return text
    },

    markdown: (text) => {

        // turn an < and > characters the user may have typed-in into html entities (to prevent them from writing html directly)
        text = text.replace(/</g, '&lt;')
        text = text.replace(/>/g, '&gt;')

        // simple tags
        let tags = [{ name: 'bold', htmlTag: 'b' },
        { name: 'italic', htmlTag: 'i' },
        { name: 'underline', htmlTag: 'u' },
        { name: 'quote', htmlTag: 'blockquote' }]
        var pattern1 = "\\["
        var pattern2 = "\\]"
        var pattern3 = "\\[/"
        var pattern4 = "\\]"
        tags.forEach(tag => {
            var regExp1 = new RegExp(`${pattern1}${tag.name}${pattern2}`, "g")
            var regExp2 = new RegExp(`${pattern3}${tag.name}${pattern4}`, "g")
            text = text.replace(regExp1, '<' + tag.htmlTag + '>')
            text = text.replace(regExp2, '</' + tag.htmlTag + '>')
            // text = text.replace(/\[b\]/g, '<b>')
            // text = text.replace(/\[\/b\]/g, '</b>')
        })

        let smallFontSize = '8px'
        let mediumFontSize = '16px'
        let largeFontSize = '26px'
        let superLargeFontSize = '40px'
        text = text.replace(/\[size small\]/g, `<span style="font-size: ${smallFontSize}">`)//   '<span class="forum-user font-size-small">')
        text = text.replace(/\[size medium\]/g, `<span style="font-size: ${mediumFontSize}">`)
        text = text.replace(/\[size large\]/g, `<span style="font-size: ${largeFontSize}">`)
        text = text.replace(/\[size super-large\]/g, `<span style="font-size: ${superLargeFontSize}">`)
        text = text.replace(/\[\/size\]/g, '</span>')

        var match = text.match(/\[color([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let color = match[1].substring(1, bracketpos)
                if (!color) {
                    break;
                }
                let testTag = '[color ' + color + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/color]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/color]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    break;
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<span style="color: ${color}">${taggedText}</span>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[color([^<]+)/i)
        }

        match = text.match(/\[link([^<]+)/i)

        while (match && match.length > 0) {
            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let link = match[1].substring(1, bracketpos)
                if (!link) {
                    break;
                }
                let testTag = '[link ' + link + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/link]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/link]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    // break;
                    taggedText = link
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<a target="_blank" rel="noopener noreferrer" href="${link}">${taggedText}</a>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[link([^<]+)/i)
        }



        match = text.match(/\[image([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let image = match[1].substring(1, bracketpos)
                if (!image) {
                    break;
                }
                let testTag = '[image ' + image + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/image]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/image]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)

                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide

                if (taggedText) {
                    text += `<figure style="text-align: center">
                                 <img alt="" src="${image}"/>
                                 <figcaption>${taggedText}</figcaption>
                            </figure>`
                }
                else {

                    text += `<img alt="" src="${image}"/>`
                }
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[image([^<]+)/i)
        }

        // convert linefeeds
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

        return text
    },

    tagsToHtml: (text) => {

        // turn an < and > characters the user may have typed-in into html entities (to prevent them from writing html directly)
        text = text.replace(/</g, '&lt;')
        text = text.replace(/>/g, '&gt;')

        // simple tags
        let tags = [{ name: 'bold', htmlTag: 'b' },
        { name: 'italic', htmlTag: 'i' },
        { name: 'underline', htmlTag: 'u' },
        { name: 'quote', htmlTag: 'blockquote' }]
        var pattern1 = "\\["
        var pattern2 = "\\]"
        var pattern3 = "\\[/"
        var pattern4 = "\\]"
        tags.forEach(tag => {
            var regExp1 = new RegExp(`${pattern1}${tag.name}${pattern2}`, "g")
            var regExp2 = new RegExp(`${pattern3}${tag.name}${pattern4}`, "g")
            text = text.replace(regExp1, '<' + tag.htmlTag + '>')
            text = text.replace(regExp2, '</' + tag.htmlTag + '>')
            // text = text.replace(/\[b\]/g, '<b>')
            // text = text.replace(/\[\/b\]/g, '</b>')
        })

        let smallFontSize = '8px'
        let mediumFontSize = '16px'
        let largeFontSize = '26px'
        let superLargeFontSize = '40px'
        text = text.replace(/\[size small\]/g, `<span style="font-size: ${smallFontSize}">`)//   '<span class="forum-user font-size-small">')
        text = text.replace(/\[size medium\]/g, `<span style="font-size: ${mediumFontSize}">`)
        text = text.replace(/\[size large\]/g, `<span style="font-size: ${largeFontSize}">`)
        text = text.replace(/\[size super-large\]/g, `<span style="font-size: ${superLargeFontSize}">`)
        text = text.replace(/\[\/size\]/g, '</span>')

        var match = text.match(/\[color([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let color = match[1].substring(1, bracketpos)
                if (!color) {
                    break;
                }
                let testTag = '[color ' + color + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/color]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/color]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    break;
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<span style="color: ${color}">${taggedText}</span>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[color([^<]+)/i)
        }

        match = text.match(/\[link([^<]+)/i)

        while (match && match.length > 0) {
            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let link = match[1].substring(1, bracketpos)
                if (!link) {
                    break;
                }
                let testTag = '[link ' + link + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/link]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/link]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    // break;
                    taggedText = link
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<a target="_blank" rel="noopener noreferrer" href="${link}">${taggedText}</a>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[link([^<]+)/i)
        }



        match = text.match(/\[image([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let image = match[1].substring(1, bracketpos).trim()
                let imageAlignment = ''
                if (image.includes(' ')) {
                    let split = image.split(' ')
                    if (split.length === 2) {
                        image = split[0]
                        imageAlignment = split[1]
                    }
                }
                if (!image) {
                    break;
                }
                let testTag = '[image ' + image + ' ' + imageAlignment + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/image]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/image]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                /*
[image http://localhost/static/forum/arginusae1.png left][/image]
*/
                if (taggedText) {
                    text += `<figure><img alt="" src="${image}" ALIGN="${imageAlignment}" style="padding: 10px;"/><figcaption>${taggedText}</figcaption></figure>`
                }
                else {
                    text += `<figure><img alt="" src="${image}" ALIGN="${imageAlignment}" style="padding: 10px;"/></figure>`
                }
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[image([^<]+)/i)
        }


        match = text.match(/\[youtube([^<]+)/i)
        while (match && match.length > 0) {
            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let youtubeUrl = match[1].substring(1, bracketpos)
                if (!youtubeUrl) {
                    break;
                }
                let testTag = '[youtube ' + youtubeUrl + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/youtube]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/youtube]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    // break;
                    taggedText = youtubeUrl
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<iframe width="420" height="315" src="https://www.youtube.com/embed/${youtubeUrl}"></iframe>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[youtube([^<]+)/i)
        }








        // convert linefeeds
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

        return text
    }

}


export default Utility