import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import "./GenericPaging.scss"

export function GenericPaging({ itemsName, itemsCount, limit, currentPage, pageChange, limitChange }) {
    const { state } = useContext(StoreContext);
    const [pageButtons, setPageButtons] = useState([])
    const [pages, setPages] = useState(0)

    useEffect(() => {
        if (parseInt(itemsCount) > 0) {
            let pages_ = Math.ceil(parseInt(itemsCount) / parseInt(limit))
            if (pages_ > 0) {
                setPages(pages_)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount])

    useEffect(() => {
        setPageButtonsDisplay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    useEffect(() => {
        let pages_ = Math.ceil(parseInt(itemsCount) / parseInt(limit))
        if (pages_ > 0) {
            setPages(pages_)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    useEffect(() => {
        if (pages > 0) {
            setPageButtonsDisplay()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    const setPageButtonsDisplay = () => {
        let arr = []
        let showButtons = []
        let showDots = []
        let i = 0

        !showButtons.includes(currentPage - 1) && (currentPage - 1) >= 0 && (currentPage - 1 < pages) && showButtons.push(currentPage - 1)
        !showButtons.includes(currentPage) && (currentPage >= 0) && (currentPage < pages) && showButtons.push(currentPage)
        !showButtons.includes(currentPage + 1) && (currentPage + 1 >= 0) && (currentPage + 1 < pages) && showButtons.push(currentPage + 1)

        if (currentPage < 2) {
            showButtons.push(2)
        }

        !showButtons.includes(0) && showButtons.unshift(0)

        if (currentPage > pages - 2) {
            !showButtons.includes(pages - 3) && (pages - 3 > 0) && showButtons.push(pages - 3)
        }

        !showButtons.includes(pages - 1) && (pages - 1) > 0 && showButtons.push(pages - 1)

        let gaps = 0
        for (i = 0; i < pages; i++) {
            if (showButtons.includes(i)) {
                continue
            }
            gaps++
            while (showButtons.includes(i) === false && i < pages) {
                i++
            }
        }

        let dotsPushed = 0

        for (i = 0; i < pages; i++) {
            if (showButtons.includes(i)) {
                if (dotsPushed > 0) {
                    break
                }
                continue
            }
            if (dotsPushed < 3) {
                showDots.push(i)
                dotsPushed++
            }
        }

        if (gaps === 2) {
            dotsPushed = 0
            // get last dot pushed
            let lastDotPushed = showDots[showDots.length - 1]
            let startFromHere = -1
            for (var j = lastDotPushed; j < pages; j++) {
                if (showButtons.includes(j)) {
                    startFromHere = j
                    break;
                }
            }

            if (startFromHere > 0) {
                for (i = startFromHere; i < pages; i++) {
                    if (showButtons.includes(i)) {
                        if (dotsPushed > 0) {
                            break;
                        }
                        continue;
                    }
                    if (dotsPushed < 3) {
                        showDots.push(i)
                        dotsPushed++
                    }
                }
            }
        }

        for (i = 0; i < pages; i++) {
            let displayType = ''
            if (showButtons.includes(i)) {
                displayType = 'button'
            }
            else {
                if (showDots.includes(i)) {
                    displayType = 'dot'
                }
            }

            arr.push({ page: i, displayType: displayType })
        }

        setPageButtons(arr)
    }

    const checkLimitChange = evt => {
        let value = evt.target.value
        if (value) {
            value = parseInt(value)
            if (value > 0) {
                limitChange(value)
            }
        }
        else {
            limitChange(value)
        }
    }

    return (
        <div className="generic-paging">
            <div>{itemsCount} {itemsName}{itemsCount > 1 ? `s` : ``}</div>
            { pages > 1 ?
                <div className={`page-buttons ${state.os}`}>
                    <div className={currentPage > 0 ? 'page-button arrow' : 'page-button arrow noop'}
                        onClick={
                            currentPage > 0 ?
                                () => pageChange(currentPage - 1)
                                :
                                null
                        }
                    ><span className={`span_${state.os}`}>&#x3c;</span></div>
                    {pageButtons.map((button, index) => {
                        return (
                            button.displayType === 'button' ?
                                <div key={index} className={currentPage === button.page ? 'page-button active' : 'page-button'} onClick={() => pageChange(button.page)}><span className={`span_${state.os}`}>{button.page + 1}</span></div>
                                :
                                button.displayType === 'dot' ?
                                    <div key={index} className="page-dot">.</div>
                                    :
                                    <div key={index}></div>
                        )
                    }
                    )}
                    <div className={currentPage + 1 < pages ? 'page-button arrow' : 'page-button arrow noop'}
                        onClick={
                            currentPage + 1 < pages ?
                                () => pageChange(currentPage + 1)
                                :
                                null
                        }
                    ><span className={`span_${state.os}`}>&#x3e;</span></div>
                </div>
                :
                <div className="low-items-spacer"></div>
            }
            { itemsCount > 0 ?
                <div className="items-per-page">{itemsName}s per page:
                <input type="text" name="items_per_page" value={limit} onChange={checkLimitChange} />
                </div>
                : <div className="low-items-spacer"></div>
            }
        </div>
    )
}
export default GenericPaging;