//eslint-disable-next-line
import React, { useContext, useState, useEffect, useRef } from "react";
import { StoreContext } from "./context/StoreContext";
import { useRoutes } from "hookrouter";
import ApiHelper from './objects/ApiHelper'
import Utility from './objects/Utility'
import routes from "./consts/routes"
import NotFoundPage from "./functions/NotFoundPage/NotFoundPage"
import './App.css';

const App = () => {
    const { actions } = useContext(StoreContext)
    const [logoutCheck, setLogoutCheck] = useState(0)
    const [intervalTokenRenew, setIntervalTokenRenew] = useState(0)
    const intervalTokenRenewRef = useRef(intervalTokenRenew);
    intervalTokenRenewRef.current = intervalTokenRenew

    useEffect(() => {
        tryLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const tryLogin = () => {
        let token = window.localStorage.getItem('token')
        if (token) {
            renewToken(token)
        }
        else {
            tryRememberKeyLogin()
        }
    }

    const renewToken = (token) => {
        ApiHelper.reLoginUser(token).then((data) => {
            if (data.success) {
                let newToken = data.token
                window.localStorage.setItem('token', newToken)
                var tokenTime = Math.round((new Date()).getTime() / 1000);
                window.localStorage.setItem('tokenTime', tokenTime)
                loginUser(data)
            }
            else {
                tryRememberKeyLogin()
            }
        }
        ).catch(error => {
            if (error && error.message) {
                tryRememberKeyLogin()
            }
        })
    }

    const tryRememberKeyLogin = () => {
        let rememberKey = window.localStorage.getItem('rememberKey')
        if (rememberKey) {
            ApiHelper.checkRememberKey(rememberKey).then((data) => {
                if (data && data.hasOwnProperty('userKey')) {
                    loginUser(data)
                }
            },
                error => {
                    tryLogout()
                }
            )
        }
        else {
            tryLogout()
        }
    }

    const tryLogout = () => {
        if( logoutCheck === 0 ) {
            setLogoutCheck( Math.round((new Date()).getTime() / 1000) )
            setTimeout(() => {
                tryLogin()
            }, 100)
        }
        else {
            actions.logoutUser()
            setLogoutCheck(0)
        }
    }

    const loginUser = (data) => {
        let user = {
            key: data.userKey,
            name: data.userName,
            email: data.userEmail,
            location: data.userLocation,
            createdDate: data.userCreatedDate,
            privilege: data.userPrivilege,
            status: data.userStatus,
            tagline: data.userTagline,
            token: data.token,
            avatarAvailable: data.userAvatarAvailable,
            rememberKey: data.rememberKey,
            forumLikes: data.userForumLikes,
            newsViews: data.userNewsViews,
            newsLikes: data.userNewsLikes,
            blogLikes: data.userBlogLikes,
            blogViews: data.userBlogViews
        }
        actions.loginUser(user)
        setIntervalForTokenRefresh()
        setLogoutCheck(0)
    }

    const setIntervalForTokenRefresh = () => {
        if (intervalTokenRenewRef.current === 0) {
            let intervalId = setInterval(() => {
                let token = window.localStorage.getItem('token') 
                if (token) {
                    renewToken(token)
                }
                else {
                    tryRememberKeyLogin()
                }
            }, (200000))
            setIntervalTokenRenew(intervalId)
        }
    }

    const handleMouseDown = e => {
        actions.mouseDown(e)
    }

    const handleMouseUp = e => {
        actions.mouseUp(e)
    }

    const handleReturnKey = e => {
        if (13 === e.keyCode) {
            actions.returnKey(e)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    });

    useEffect(() => {
        document.addEventListener("mouseup", handleMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    });

    useEffect(() => {
        document.addEventListener("keyup", handleReturnKey);
        return () => {
            document.removeEventListener("keyup", handleReturnKey);
        };
    });

    useEffect(() => {
        const handleResize = () => {
            actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useEffect(() => {
        actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
        let os = Utility.detectOS()
        actions.os(os)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const routeResult = useRoutes(routes);

    return routeResult || <NotFoundPage />;
}

export default App;
