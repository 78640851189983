import { types } from "./reducers";
export const useActions = (state, dispatch) => {

    // function addTechIfNotInList(newTech) {
    //     const techIndex = state.techList.indexOf(newTech);
    //     if (techIndex !== -1) {
    //         alert("Tech is defined in list");
    //     } else {
    //         dispatch({ type: types.ADDs_TO_TECH_LIST, payload: newTech });
    //     }
    // }

    function updateUserAction(actionType, actionArray) {
        if( !actionType || !actionArray || Array.isArray(actionArray) === false ) {
            console.warn('invalid parameters for updateUserAction')
            if( actionType ) {
                console.warn('actionType: ', actionType)
            }
            if( actionArray ) {
                console.warn('actionArray')
            }
            return
        }
        let user = JSON.parse(JSON.stringify(state.user))
        if (user.hasOwnProperty(actionType)) {
            user[actionType] = actionArray
            dispatch({ type: types.UPDATE_USER_ACTION, payload: user })
        }
        else {
            console.warn('nonexistent action type: ', actionType)
        }
    }

    function loginUser(user) {
        window.localStorage.setItem('token', user.token)
        var tokenTime = Math.round((new Date()).getTime() / 1000);
        window.localStorage.setItem('tokenTime', tokenTime)
        dispatch({ type: types.LOGIN_USER, payload: user });
        if (user.rememberKey) {
            localStorage.setItem('rememberKey', user.rememberKey)
        }
    }

    function logoutUser() {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('rememberKey')
        window.localStorage.removeItem('tokenTime')
        dispatch({ type: types.LOGOUT_USER, payload: [] });
    }

    function mouseDown(evt) {
        dispatch({ type: types.MOUSE_DOWN, payload: evt });
    }

    function mouseUp(evt) {
        dispatch({ type: types.MOUSE_UP, payload: evt });
    }

    function returnKey(evt) {
        dispatch({ type: types.RETURN_KEY, payload: evt });
    }

    function windowWidthHeight(evt) {
        dispatch({ type: types.WINDOW_WIDTH_HEIGHT, payload: evt })
    }

    function addErrorMessages(message) {
        dispatch({ type: types.ERROR_MESSAGES, payload: message })
    }

    function clearErrorMessages() {
        dispatch({ type: types.CLEAR_ERROR_MESSAGES, payload: [] })
    }

    function createPostIdOpen(message) {
        dispatch({ type: types.CREATE_POST_ID_OPEN, payload: message })
    }

    function reloadPosts(message) {
        dispatch({ type: types.RELOAD_POSTS, payload: message })
    }

    function forumThreads(message) {
        dispatch({ type: types.FORUM_THREADS, payload: message })
    }

    function waiting(message) {
        dispatch({ type: types.WAITING, payload: message })
    }

    function forumPostsDeleted(message) {
        dispatch({ type: types.FORUM_POSTS_DELETED, payload: message })
    } 

    function forumPostAdded(message) {
        dispatch({ type: types.FORUM_POST_ADDED, payload: message })
    } 

    function avatarRefreshString(message) {
        dispatch({ type: types.AVATAR_REFRESH_STRING, payload: message })
    } 

    function os(message) {
        dispatch({ type: types.OS, payload: message })
    } 

    return {
        loginUser,
        logoutUser,
        mouseDown,
        mouseUp,
        returnKey,
        windowWidthHeight,
        addErrorMessages,
        clearErrorMessages,
        createPostIdOpen,
        reloadPosts,
        forumThreads,
        waiting,
        updateUserAction,
        forumPostsDeleted,
        forumPostAdded,
        avatarRefreshString,
        os
    };
};