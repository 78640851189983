import ApiConnector from './ApiConnector';

let ApiHelper = {

    checkForImage: async (imageResource) => {
        return new Promise(async (resolve, reject) => {
            let params = { imageResource }
            ApiConnector.call('post', 'cryptoapi/checkForImage', params, null).then((response) => {
                resolve(response)
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    checkToken: async () => {
        let token = window.localStorage.getItem('token')

        return new Promise(async (resolve, reject) => {
            if (!token) {
                reject()
                return
            }
            let params = {}
            ApiConnector.call('post', 'cryptoapi/checkToken', params, token).then((response) => {
                resolve(response)
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => console.log('caught error: ', error))
    },

    likePost: async (forumKey) => {
        return new Promise(async (resolve, reject) => {
            let token = localStorage.getItem('token')
            let params = { forumKey: parseInt(forumKey) }
            ApiConnector.call('post', 'cryptoapi/forum/like', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    sendVerificationCode: async () => {
        let token = localStorage.getItem('token')
        return new Promise(async (resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/sendVerificationCode', null, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    validateVerificationCode: async (verificationCode) => {
        let token = localStorage.getItem('token')
        return new Promise(async (resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/validateVerificationCode', {verificationCode}, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    getNewsNewerThan: (newsKey) => {
        return new Promise((resolve, reject) => {
            let params = { newsKey: parseInt(newsKey) }
            let token = localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/news/newer', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    getUserForumActivity: (userKey) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/user/forumActivity', { userKey }, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    getNewsKeys: () => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/news/keys', {}, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    getNews: (limit, page, newerThanNewsKey = null) => {
        return new Promise((resolve, reject) => {
            let params = { limit: parseInt(limit), page: parseInt(page), newerThanNewsKey }
            let token = localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/news/feed', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    searchNews: (searchTerm) => {
        return new Promise((resolve, reject) => {
            let params = { searchTerm }
            ApiConnector.call('post', 'cryptoapi/news/search', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    newsView: (newsKey) => {
        return new Promise((resolve, reject) => {
            let params = { key: newsKey }
            ApiConnector.call('post', 'cryptoapi/news/view', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumTopicLastPost: (topicKey) => {
        let params = { topicKey: topicKey }
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/forumTopicLastPost', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumTopicLatestThreads: (topicKey, numberToGet) => {
        let params = { topicKey, numberToGet }
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/forumTopicLatestThreads', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumPostCounts: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/forum/postCounts', {}, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumTopics: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/forumTopic', null, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumTopicThreads: (id, page, limit) => {
        return new Promise((resolve, reject) => {
            let params = { id, page, limit }
            let token = window.localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/forumTopicThreads', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumMajorTopicThreads: (id, page, limit) => {
        return new Promise((resolve, reject) => {
            let params = { id, page: parseInt(page), limit: parseInt(limit) }
            let token = window.localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/forumMajorTopicThreads', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    forumSearchTopicThreads: (typeTopic, id, searchTerm, page, limit) => {
        return new Promise((resolve, reject) => {
            let params = { typeTopic, id, searchTerm, page: parseInt(page), limit: parseInt(limit) }
            let token = window.localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/forumSearchTopicThreads', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { console.log('forumSearchTopicThreads ', error) })
    },



    newsScaffold: (searchTerm) => {
        return new Promise((resolve, reject) => {
            let params = {}
            if (searchTerm) {
                params = { searchTerm }
            }
            ApiConnector.call('post', 'cryptoapi/news/scaffold', params).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })

    },

    newsItems: (newsKeys) => {
        return new Promise((resolve, reject) => {
            let params = { newsKeys }
            ApiConnector.call('post', 'cryptoapi/news/newsItems', params).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })

    },

    forumThreadKeys: (topicKey, searchTerm) => {
        return new Promise((resolve, reject) => {
            let params = { topicKey }
            if (searchTerm) {
                params = { topicKey, searchTerm }
            }
            ApiConnector.call('post', 'cryptoapi/forumThreadKeys', params).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })

    },



    forumGetThreads: (threadsToGet) => {
        return new Promise((resolve, reject) => {
            let params = { threadsToGet }
            ApiConnector.call('post', 'cryptoapi/forumGetThreads', params).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })
    },



    forumGetThread: (forumKey) => {
        return new Promise((resolve, reject) => {
            let params = { forumKey }
            ApiConnector.call('post', 'cryptoapi/forumGetThread', params).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })
    },









    adminUserList: (params) => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/admin/user/list', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from adminUserList') })
    },

    discordUserInvites: (params) => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/admin/discord/userInvites', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from adminUserList') })
    },

    adminGet: (params) => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/admin/get', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from adminGet') })
    },

    getDiscordCurrentInvite: () => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/admin/discord/getCurrentInvite', null, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from admin/discord/getCurrentInvite') })
    },

    discordSetInvite: (discordInviteCode) => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            let params = {discordInviteCode}
            ApiConnector.call('post', 'cryptoapi/admin/discord/setInvite', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from admin/discord/setInvite') })
    },

    userUsedDiscordInviteCode: (inviteCode) => {
        return new Promise((resolve, reject) => {
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            let params = {inviteCode}
            ApiConnector.call('post', 'cryptoapi/userUsedDiscordInviteCode', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from cryptoapi/userUsedDiscordInviteCode') })
    },

    adminDeleteForumPost: (forumKey) => {
        return new Promise((resolve, reject) => {
            if (!parseInt(forumKey)) {
                return reject({ error: { message: 'no auth token' } })
            }
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            let params = { forumKey }
            ApiConnector.call('post', 'cryptoapi/admin/forum/delete', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from adminAddNews') })
    },

    forumDeletePost: (userKey, forumKey) => {
        return new Promise((resolve, reject) => {
            if (!parseInt(userKey)) {
                return reject({ error: { message: 'no user key' } })
            }
            if (!parseInt(forumKey)) {
                return reject({ error: { message: 'no forum key' } })
            }
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            let params = { userKey, forumKey }
            ApiConnector.call('post', 'cryptoapi/forum/delete', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from forumDeletePost') })
    },

    forumUpdatePost: (forumKey, subject, text) => {
        return new Promise((resolve, reject) => {
            if (!parseInt(forumKey)) {
                return reject({ error: { message: 'no forum key' } })
            }
            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            let params = { forumKey, subject, text }
            ApiConnector.call('post', 'cryptoapi/forum/update', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from forumUpdatePost') })
    },

    adminAddNews: (params) => {
        return new Promise((resolve, reject) => {

            let token = window.localStorage.getItem('token')
            if (!token) {
                return reject({ error: { message: 'no auth token' } })
            }
            console.log('calling cryptoapi/admin/news/add with params ', params)
            ApiConnector.call('post', 'cryptoapi/admin/news/add', params, token).then((response) => {
                console.log('got response ', response)
                if (response.hasOwnProperty('error')) {
                    return reject(response)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))

        }).catch(error => { console.warn('caught error from adminAddNews') })
    },




    forumPost: (topicKey, parentKey, topParentKey, subject, text) => {

        return new Promise((resolve, reject) => {
            let sanitizedSubject = ''
            let sanitizedText = ''
            let cussWords = ['fuck', 'faggot', 'gayboy', 'cocksucker', 'cock sucker', 'asshole', 'nigger', 'jewboy', 'chink'];
            sanitizedSubject = subject.replace(/'<'/gi, '&lt;')
            sanitizedText = text.replace(/'<'/gi, '&lt;')
            cussWords.forEach(word => {
                sanitizedSubject = sanitizedSubject.replace(/`${word}`/gi, '')
                sanitizedText = sanitizedText.replace(/`${word}`/gi, '')
            })
            if (sanitizedSubject.length < 2 || sanitizedText.length < 2) {
                reject({ error: { message: 'non-polite text detected' } })
            }
            let params = { topicKey, parentKey, topParentKey, subject: sanitizedSubject, text: sanitizedText }
            let token = window.localStorage.getItem('token')
            if (!token) {
                reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/forum/post2', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })

    },

    blogDelete: (blogKey) => {
        return new Promise((resolve, reject) => {
            let params = { blogKey }
            let token = window.localStorage.getItem('token')
            if (!token) {
                reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/blog/delete', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })
    },

    blogPost: (title, text, note, draftMode, blogKey) => {

        return new Promise((resolve, reject) => {
            let sanitizedTitle = ''
            let sanitizedText = ''
            let cussWords = ['fuck', 'faggot', 'gayboy', 'cocksucker', 'cock sucker', 'asshole', 'nigger', 'jewboy', 'chink'];
            sanitizedTitle = title.replace(/'<'/gi, '&lt;')
            sanitizedText = text.replace(/'<'/gi, '&lt;')
            cussWords.forEach(word => {
                sanitizedTitle = sanitizedTitle.replace(/`${word}`/gi, '')
                sanitizedText = sanitizedText.replace(/`${word}`/gi, '')
            })
            if (sanitizedTitle.length < 2 || sanitizedText.length < 2) {
                reject({ error: { message: 'non-polite text detected' } })
            }
            let params = { title: sanitizedTitle, text: sanitizedText, note, draftMode, blogKey }
            let token = window.localStorage.getItem('token')
            if (!token) {
                reject({ error: { message: 'no auth token' } })
            }
            ApiConnector.call('post', 'cryptoapi/blog/post', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response)
                    }
                    else {
                        reject({ error: { message: 'unknown error' } })
                    }
                }
            }).catch(err => resolve(err))
        }).catch(error => { console.warn('caught error from ApiConnector.call') })

    },

    userAction: (actionType, actionKey) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            let params = { actionType, actionKey }
            ApiConnector.call('post', 'cryptoapi/user/action', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    externalImageCheck: (imageURL) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            let params = { imageURL }
            ApiConnector.call('post', 'cryptoapi/externalImageCheck', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })
    },

    attemptLogin: (login, password, loginRemember, navigator, info) => {
        loginRemember = loginRemember === 'on' ? true : false
        return new Promise((resolve, reject) => {
            let params = { login, password, loginRemember, navigator, info }
            ApiConnector.call('post', 'cryptoapi/login', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        let forumLikes = response.success.userForumLikes
                        response.success.userForumLikes = []
                        response.success.userForumLikes.push.apply(response.success.userForumLikes, forumLikes.split(",").map(Number));

                        let blogLikes = response.success.userBlogLikes
                        response.success.userBlogLikes = []
                        response.success.userBlogLikes.push.apply(response.success.userBlogLikes, blogLikes.split(",").map(Number));

                        let newsLikes = response.success.userNewsLikes
                        response.success.userNewsLikes = []
                        response.success.userNewsLikes.push.apply(response.success.userNewsLikes, newsLikes.split(",").map(Number));

                        let newsViews = response.success.userNewsViews
                        response.success.userNewsViews = []
                        response.success.userNewsViews.push.apply(response.success.userNewsViews, newsViews.split(",").map(Number));

                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })

    },

    checkRememberKey: (rememberKey) => {
        return new Promise((resolve, reject) => {

            let params = { rememberKey }
            ApiConnector.call('post', 'cryptoapi/checkRememberKey', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        let forumLikes = response.success.userForumLikes
                        response.success.userForumLikes = []
                        response.success.userForumLikes.push.apply(response.success.userForumLikes, forumLikes.split(",").map(Number));

                        let blogLikes = response.success.userBlogLikes
                        response.success.userBlogLikes = []
                        response.success.userBlogLikes.push.apply(response.success.userBlogLikes, blogLikes.split(",").map(Number));

                        let newsLikes = response.success.userNewsLikes
                        response.success.userNewsLikes = []
                        response.success.userNewsLikes.push.apply(response.success.userNewsLikes, newsLikes.split(",").map(Number));

                        let newsViews = response.success.userNewsViews
                        response.success.userNewsViews = []
                        response.success.userNewsViews.push.apply(response.success.userNewsViews, newsViews.split(",").map(Number));
                        
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })

    },

    logout: () => {
        return new Promise((resolve, reject) => {
            let params = {}
            let token = localStorage.getItem('token')
            ApiConnector.call('post', 'cryptoapi/logout', params, token).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                    return
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        }).catch(error => { })

    },




    profileDisplay: (userKey) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            if (token) {
                let params = { userKey: parseInt(userKey) }
                ApiConnector.call('post', 'cryptoapi/profile/display', params, token).then((response) => {
                    if (response.hasOwnProperty('error')) {
                        reject(response.error)
                    }
                    else {
                        if (response.hasOwnProperty('success')) {
                            resolve(response.success)
                        }
                        else {
                            reject({ message: 'unknown error' })
                        }
                    }
                },
                    (error) => {
                        reject(error)
                    })
            }
        }).catch(error => console.log('exception in profileDisplay:', error))
    },

    getDiscordStatus: () => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            if (token) {
                ApiConnector.call('post', 'cryptoapi/getDiscordStatus', {}, token).then((response) => {
                    if (response.hasOwnProperty('error')) {
                        reject(response.error)
                    }
                    else {
                        if (response.hasOwnProperty('success')) {
                            resolve(response.success)
                        }
                        else {
                            reject({ message: 'unknown error' })
                        }
                    }
                },
                    (error) => {
                        reject(error)
                    })
            }
        }).catch(error => console.log('exception in getDiscordStatus:', error))
    },





    updateProfile: (params) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('token')
            if (token) {
                ApiConnector.call('post', 'cryptoapi/profile/update', params, token).then((response) => {
                    if (response.hasOwnProperty('error')) {
                        reject(response.error)
                    }
                    else {
                        if (response.hasOwnProperty('success')) {
                            resolve(response.success)
                        }
                        else {
                            reject({ message: 'unknown error' })
                        }
                    }
                },
                    (error) => {
                        reject(error)
                    })
            }
        }).catch(error => console.log('exception in updateProfile:', error))
    },

    reLoginUser: (token) => {
        return new Promise((resolve, reject) => {
            let params = { token }
            ApiConnector.call('post', 'cryptoapi/authenticateToken', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {

                        let forumLikes = response.success.userForumLikes
                        response.success.userForumLikes = []
                        response.success.userForumLikes.push.apply(response.success.userForumLikes, forumLikes.split(",").map(Number));

                        let blogLikes = response.success.userBlogLikes
                        response.success.userBlogLikes = []
                        response.success.userBlogLikes.push.apply(response.success.userBlogLikes, blogLikes.split(",").map(Number));

                        let newsLikes = response.success.userNewsLikes
                        response.success.userNewsLikes = []
                        response.success.userNewsLikes.push.apply(response.success.userNewsLikes, newsLikes.split(",").map(Number));

                        let newsViews = response.success.userNewsViews
                        response.success.userNewsViews = []
                        response.success.userNewsViews.push.apply(response.success.userNewsViews, newsViews.split(",").map(Number));




                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (response) => {
                    reject(response.error)
                })
        })
    },

    attemptRegister: (displayName, email, password) => {
        return new Promise((resolve, reject) => {
            let params = { displayName, password, email }
            ApiConnector.call('post', 'cryptoapi/register', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },

    authenticateToken: (token) => {
        return new Promise((resolve, reject) => {
            let params = { token }
            ApiConnector.call('post', 'cryptoapi/authenticateToken', params, null).then((response) => {
                //const data = response.json();
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {

                        let forumLikes = response.success.userForumLikes
                        response.success.userForumLikes = []
                        response.success.userForumLikes.push.apply(response.success.userForumLikes, forumLikes.split(",").map(Number));

                        let blogLikes = response.success.userBlogLikes
                        response.success.userBlogLikes = []
                        response.success.userBlogLikes.push.apply(response.success.userBlogLikes, blogLikes.split(",").map(Number));

                        let newsLikes = response.success.userNewsLikes
                        response.success.userNewsLikes = []
                        response.success.userNewsLikes.push.apply(response.success.userNewsLikes, newsLikes.split(",").map(Number));

                        let newsViews = response.success.userNewsViews
                        response.success.userNewsViews = []
                        response.success.userNewsViews.push.apply(response.success.userNewsViews, newsViews.split(",").map(Number));

                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })
    },


    getBlogs: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'cryptoapi/getBlogs', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },



    getBlog: (id) => {
        return new Promise((resolve, reject) => {
            let params = { id }
            ApiConnector.call('post', 'cryptoapi/getBlog', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },


















}

export default ApiHelper