import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import HomeForum from "./HomeForum/HomeForum"
import Blogs from "../Blogs/Blogs"
import Admin from "../Admin/Admin"
import News from '../News/News'

import "./Home.scss"

const Home = () => {
    const { state } = useContext(StoreContext);

    return (
        <div className="home">

            <div className="news-section">
            <div className="book-deal">
                       
                        <div>
                            Cryptozoology.com has been shut down
                          </div>
                    </div>

                <div>


                    </div>
            </div>

        </div>
    );
}
export default Home;

/*
                    <div className="book-deal">
                        <img src="https://m.media-amazon.com/images/I/4109mC5T4dL.jpg" alt="cover for book" />
                        <div>
                          <div>Amazon.com Kindle book deal</div>
                          <div>$2.99</div>
                          <div><a href="https://www.amazon.com/dp/B07NP1JQGH" target="_blank" rel="noopener noreferrer">https://www.amazon.com/dp/B01AMB3V0W</a></div>
                          </div>
                    </div>
*/